<template>
    <div class="video-search">
        <form ref="form" class="m-b-4" @submit.prevent="onSearch">
            <div class="video-search-form">
                <Input
                    id="video-search"
                    class="video-search-input"
                    name="query"
                    placeholder="Enter keyword to search for a video"
                    type="text"
                    label="Search Video"
                    autocomplete="off"
                    :has-margin-bottom="false"
                />

                <Button has-border size="small" type="submit" :disabled="isLoading">
                    Search
                </Button>
            </div>
        </form>

        <div v-show="videos.length" class="video-search-filters m-b-2">
            <p class="m-r-1">Sort Results</p>

            <Menu button-icon="filter-icon">
                <DropDownItem @click.prevent="onFilter('relevance')"> Relevance </DropDownItem>
                <DropDownItem @click.prevent="onFilter('date')"> Date </DropDownItem>
                <DropDownItem @click.prevent="onFilter('viewCount')"> View Count </DropDownItem>
            </Menu>
        </div>

        <div class="video-list">
            <div v-for="(video, index) in videos" :key="index" class="video-list-item">
                <VideoCard
                    :video="video"
                    :active="value && video.url === value.url"
                    @click="$emit('input', video)"
                />
            </div>

            <TextButton
                v-show="videos.length && !isLoading"
                class="video-search-load-more"
                @click="onLoadMore"
            >
                load more
            </TextButton>
        </div>

        <div v-if="isLoading" class="video-search-loading">
            <Spinner />
        </div>
    </div>
</template>

<script>
import { YouTubeVideoService } from '@/services/youTubeVideoService';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';
import { VideoService } from '@/services/videoService';
import TextButton from '@/components/ui/TextButton';
import Spinner from '@/components/ui/Spinner';
import Button from '@/components/ui/Button';
import { Input } from '@/components/forms';
import Menu from '@/components/ui/Menu';
import VideoCard from './VideoCard';

export default {
    name: 'VideoSearch',
    components: {
        DropDownItem,
        TextButton,
        VideoCard,
        Spinner,
        Button,
        Input,
        Menu,
    },
    props: {
        value: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            videos: [],
            sortOrder: null,
            nextPageToken: '',
            isLoading: false,
        };
    },
    created() {
        this.videoService = new VideoService(new YouTubeVideoService());
    },
    beforeDestroy() {
        this.$emit('input', undefined);
        this.videoService = null;
        delete this.videoService;
    },
    methods: {
        async onSearch() {
            this.nextPageToken = '';
            const result = await this.search();
            this.videos = result.items;
            this.nextPageToken = result.nextPageToken;
        },
        async onLoadMore() {
            const result = await this.search();
            this.videos = [...this.videos, ...result.items];
            this.nextPageToken = result.nextPageToken;
        },
        onFilter(sortOrder) {
            this.nextPageToken = '';
            this.sortOrder = sortOrder;
            this.onSearch();
        },
        async search() {
            this.isLoading = true;
            const formData = new FormData(this.$refs.form);
            const result = await this.videoService.search({
                query: formData.get('query'),
                sortOrder: this.sortOrder,
                pageToken: this.nextPageToken,
            });
            this.isLoading = false;
            return result;
        },
    },
};
</script>

<style scoped lang="scss">
.video-search-form,
.video-search-filters {
    display: flex;
    align-items: center;
}

.video-search-filters {
    text-transform: uppercase;
    font-size: $font-size-xs;
    color: $color-cadet-blue;
    justify-content: flex-end;

    .menu {
        color: $color-primary;
    }
}

.video-search-input {
    flex: 1;
    margin-right: 1rem;
}

.video-search-loading {
    padding: 2rem 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary;
}

.video-search-load-more {
    margin: 2rem auto 0;
}

.video-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    .video-card {
        &:hover,
        &:focus,
        &--active {
            background-color: $color-athens-gray;
        }
    }
}

.video-list-item {
    margin-bottom: 20px;
    padding: 0 5px;
    width: 25%;
}
</style>
