<template>
    <FormOverlay
        :title="title"
        :description="description"
        :confirm-button-text="confirmButtonText"
        :confirm-button-disabled="disableConfirmButton"
        :show-overlay="show"
        size="wide"
        @close="$emit('close')"
        @confirm="onConfirm"
    >
        <Tabs :active-tab="activeTab" :disabled="false" @onChange="activeTab = $event">
            <Tab name="Video Search">
                <VideoSearch v-model="video" />
            </Tab>

            <Tab name="Video Url">
                <VideoUrl v-model="video" />
            </Tab>

            <!-- <Tab name="Video Upload"> Video Upload </Tab> -->
        </Tabs>
    </FormOverlay>
</template>

<script>
import FormOverlay from '@/components/ui/FormOverlay';
import VideoSearch from '@/components/VideoSelector/VideoSearch';
import VideoUrl from '@/components/VideoSelector/VideoUrl';
import Tabs from '@/components/ui/Tabs';
import Tab from '@/components/ui/Tab';

export default {
    name: 'VideoSelector',
    components: {
        VideoSearch,
        FormOverlay,
        VideoUrl,
        Tabs,
        Tab,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: 'Video Upload',
        },
        description: {
            type: String,
            default: 'Search, browse or upload video',
        },
        confirmButtonText: {
            type: String,
            default: 'Select video',
        },
    },
    data() {
        return {
            activeTab: 0,
            video: undefined,
        };
    },
    computed: {
        disableConfirmButton() {
            return this.video === undefined;
        },
    },
    methods: {
        onConfirm() {
            this.$emit('input', this.video);
            this.$emit('close');
        },
    },
};
</script>
