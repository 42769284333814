<template>
    <div class="video-url">
        <Input
            id="video-url"
            v-model="url"
            class="m-b-2"
            name="query"
            placeholder="Enter YouTube video url"
            type="text"
            label="Video Url"
            autocomplete="off"
            :errors="errors"
            :has-margin-bottom="false"
        />

        <VideoCard v-if="value" :video="value" />

        <div v-if="isLoading" class="video-url-loading">
            <Spinner />
        </div>
    </div>
</template>

<script>
import { VideoService, YouTubeVideoService } from '@/services';
import { VideoProvider } from '@/enums/videoProvider.enum';
import Spinner from '@/components/ui/Spinner';
import { Input } from '@/components/forms';
import VideoCard from './VideoCard';

const videoProviders = {
    [VideoProvider.YouTube]: YouTubeVideoService,
};

export default {
    name: 'VideoUrl',
    components: {
        VideoCard,
        Spinner,
        Input,
    },
    props: {
        value: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            url: '',
            errors: [],
            isLoading: false,
        };
    },
    watch: {
        async url(value) {
            this.errors = [];
            this.$emit('input', undefined);
            const urlInfo = VideoService.parseUrl(value);
            if (!urlInfo) {
                this.errors.push('Invalid video url');
                return;
            }
            if (!videoProviders[urlInfo.provider]) {
                this.$log.debug(`No video service found for ${urlInfo.provider}`);
                return;
            }
            this.isLoading = true;
            const service = new VideoService(new videoProviders[urlInfo.provider]());
            const video = await service.find(urlInfo.id);
            this.$emit('input', video);
            this.isLoading = false;
        },
    },
    beforeDestroy() {
        this.$emit('input', undefined);
    },
};
</script>

<style lang="scss" scoped>
.video-url-loading {
    padding: 2rem 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary;
}
</style>
