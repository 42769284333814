<template>
    <div class="video-card" :class="{ 'video-card--active': active }" @click="$emit('click')">
        <div class="video-card-image">
            <img :src="video.image" :alt="video.title" />

            <div class="video-card-duration">{{ video.duration }}</div>
        </div>

        <h4 class="video-card-title">{{ video.title }}</h4>

        <p class="video-card-channel-title">{{ video.channelTitle }}</p>

        <p class="video-card-meta">{{ video.viewCount }} views • {{ published }}</p>
    </div>
</template>

<script>
import { format } from 'timeago.js';

export default {
    name: 'VideoCard',
    props: {
        video: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        published() {
            return format(this.video.publishedDate);
        },
    },
};
</script>

<style lang="scss" scoped>
.video-card {
    padding-bottom: 1rem;
    font-family: $font-family-default;
    font-size: $font-size-xs;
    color: $color-shuttle-gray;
}

.video-card-image {
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; // 16:9 ratio

    img {
        object-fit: cover;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.video-card-duration {
    border-radius: 4px;
    padding: 2px 4px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 2;
    background-color: rgba($color-dark, 0.8);
    color: $color-light;
    font-weight: $font-weight-bold;
}

.video-card-title {
    font-weight: $font-weight-bold;
    line-height: 1.17;
    color: $color-oxford-blue;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.video-card-channel-title {
    line-height: 1.5;
}

.video-card-meta {
    font-size: $font-size-xxs;
}
</style>
